<template>
  <TTView>
    <VRow>
      <VCol>
        <CompanyMatrixCard
          :matrix="matrix"
          :normalized-matrix="normalizedCompanyMatrix"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { normalizeCompanyMatrix } from '@front.backoffice/common';
import CompanyMatrixCard from '../../components/competency-matrix/positions/CompanyMatrixCard.vue';

export default {
  name: 'PositionsCompanyMatrixView',

  components: {
    CompanyMatrixCard,
  },

  inject: ['Names'],

  data() {
    return {
      matrix: [],
      loading: false,
    };
  },

  computed: {
    companyId() {
      return this.$route.params.companyId;
    },

    normalizedCompanyMatrix() {
      return normalizeCompanyMatrix(this.matrix);
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { companyId } = this;
        const data = { companyId };

        const [{ matrix = [] }] = await Promise.all([
          this.$di.api.CompetencyMatrix.PositionsCompanyMatrix(data),
        ]);

        this.matrix = matrix || [];
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
