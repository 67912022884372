<template>
  <VCard>
    <VToolbar flat>
      <VToolbarTitle>
        Матрица компании
      </VToolbarTitle>

      <VSpacer />
    </VToolbar>

    <VDivider />

    <VCardText>
      <VTreeview
        v-if="normalizedMatrix.length > 0"
        open-all
        open-on-click
        :items="normalizedMatrix"
      >
        <template #prepend="{ item }">
          <VChipGroup>
            <VChip
              small
              :color="getItemColor(item.type)"
            >
              {{ item.type }}
            </VChip>
          </VChipGroup>
        </template>

        <template #append="{ item }">
          <VChipGroup>
            <VChip
              v-if="item.type === 'skill'"
              small
              color="blue accent-1"
            >
              {{ item.item.instrument.name }}
              ({{ item.item.instrument.code }})
            </VChip>

            <VChip
              v-if="item.type === 'skill'"
              small
              color="blue accent-1"
            >
              level: {{ item.item.level }}
            </VChip>

            <VChip small>
              {{ item.id }}
            </VChip>
          </VChipGroup>
        </template>
      </VTreeview>

      <VExpansionPanels
        accordion
        flat
      >
        <VExpansionPanel>
          <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
          <VExpansionPanelContent>
            <code
              class="elevation-0 d-block"
              style="white-space: pre"
              v-text="matrix"
            />
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCardText>
  </VCard>
</template>

<script>
export default {
  name: 'CompanyMatrixCard',

  inject: ['Names'],

  props: {
    matrix: {
      type: Array,
      default: () => [],
    },

    normalizedMatrix: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getItemColor(type) {
      const dict = { position: 'orange accent-3', competency: 'orange accent-2', skill: 'orange accent-1' };
      return dict[type];
    },
  },
};
</script>
